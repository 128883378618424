.image-scale {
  width: 30%;
}
td {
  vertical-align: middle;
}

@media only screen and (min-width: 300px) and (max-width: 766px) {
  .image-scale {
    width: 100%;
  }
}
