body {
  //background: #fff;
}

section {
  min-height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

section .container {
  margin: 10px;
}

section h1 {
  font-size: 3rem;
  margin: 20px;
}

section h2 {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
}

section .text-container {
  display: flex;
  justify-content: center;
}

section .text-container .text-box {
  margin: 20px;
  padding: 20px;
  //background: #00c2cb;
}

section .text-container .text-box h3 {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.card-zoom {}

.card-zoom:hover {
  transform: scale(1.15);
  z-index: 10;
  margin: 40px;
}

p {
  text-decoration: none;
}

a:link {
  color: black;
  text-decoration: none;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 900px) {
  section h1 {
    font-size: 2rem;
    text-align: center;
  }

  section .text-container {
    flex-direction: column;
  }
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

@media only screen and (min-width: 300px) and (max-width: 766px) {
  .col-margin {
    margin-top: 30px;
  }
}
