button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.text-center-or-left {
  text-align: left;
}

.btn-green-color {
  background-color: green;
  border-color: green;
}

.btn-green-color:active {
  background-color: green;
  border-color: green;
}

.btn-green-color:focus {
  background-color: green;
  border-color: green;
}

.btn-green-color:hover {
  background-color: #015a01;
  border-color: #015a01;
}

@media only screen and (min-width: 300px) and (max-width: 766px) {
  .text-center-or-left {
    text-align: center;
  }
}
