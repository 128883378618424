:global {}

.text-left {
  text-align: left;
}

.box-shadow {
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 30%);
}

.crop {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.crop:hover {
  -webkit-line-clamp: 100;
}

.crop-only {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.set-cursor {
  cursor: pointer;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-style {
  background-color: rgba(34, 34, 34, 0.7);
  height: auto;

  .menu-desktop {
    display: flex;
  }

  .menu-mobile {
    display: none;
  }

  .m-auto {
    &.text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .text-nav {
    color: #fff;
    padding: 30px !important;
  }
}

@media (max-width: 1610px) {
  .nav-style {

    .m-auto {
      &.text {
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .nav-style {

    .m-auto {
      &.text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .text-nav {
      color: #fff;
      padding: 20px !important;
    }
  }
}

@media (max-width: 1300px) {
  .nav-style {

    .m-auto {
      &.text {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .nav-style {

    .m-auto {
      &.text {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .text-nav {
      color: #fff;
      padding: 15px !important;
    }
  }
}

@media (max-width: 1000px) {
  .offcanvas.offcanvas-end {
    width: 100% !important;
    background: #151515;
    color: #fff;
    font-weight: 600;
    font-size: 32px;
    line-height: 37.5px;

    .offcanvas-body {
      text-align: center;

      .nav-link {
        padding: 30px 0 !important;
      }

    }
  }

  .btn-close {
    background: url("../assets/icon/close.svg") center/1em auto no-repeat;
  }

  .nav-style {
    // background: none;
    height: auto;
    display: flex;
    justify-content: end;

    .menu-desktop {
      display: none;
    }

    .menu-mobile {
      display: block;
      margin: 10px 25px;
    }

    .m-auto {
      &.text {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .text-nav {
      color: #fff;
      padding: 60px !important;
    }
  }
}