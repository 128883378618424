body {
  background: #fff;
}

.first-page {
  section {
    min-height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section .container {
    margin: 10px;
  }

  section h1 {
    font-size: 3rem;
    margin: 20px;
  }

  section h2 {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  section .text-container {
    display: flex;
    justify-content: center;
  }

  section .text-container .text-box {
    margin: 20px;
    padding: 20px;
    //background: #00c2cb;
  }

  section .text-container .text-box h3 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .card-zoom {}

  .card-zoom:hover {
    transform: scale(1.15);
    z-index: 10;
    margin: 40px;
  }

  p {
    text-decoration: none;
  }

  a:link {
    color: black;
    text-decoration: none;
  }

  section {
    display: block;
    place-items: center;
    // height: 100vh;
    font-size: 32px;
    font-weight: bold;
    background: #151515;
  }

  .text-color {
    color: #ffffff;
  }

  .container-title {
    display: flex;
    justify-content: center;

    .border-title {
      border: 5px solid #ffffff;
      padding: 10px 24px;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      width: max-content;
    }
  }

  .react-player {
    &.video {
      max-height: 469px;
      max-width: 820px;
    }
  }

  iframe {
    border-radius: 15px;
  }

  .d-block {
    &.size-promotion {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }

    &.size-review {
      width: 70%;
      height: auto;
      max-height: 250px;
      max-width: 820px;
      margin: 0 auto;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
      border-radius: 10px;
    }
  }

  .container-slide {
    padding: 60px 75px 30px 75px;

    &.review {
      // padding: 60px 150px 30px 150px;
      display: flex;
      justify-content: center;

      .carousel {
        width: 100%;
      }

      .carousel-indicators {
        margin-bottom: -2rem;

        button {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          // border: 2px solid white;
          // background-color:#747474;
          // background: transparent;
        }
      }
    }
  }

  .container-button {
    display: flex;
    justify-content: center;

    .button-default {
      width: 220px;
      height: 60px;
      background: #a70000;
      border-radius: 15px;
      color: #ffffff;
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #4e0505;
      }

      &.social {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 60px;

        .icon-social {
          width: 100%;
          max-width: 48px;
          max-height: 48px;
        }
      }

      &.position {
        position: absolute;
        z-index: 1;
        bottom: 20px;
      }
    }
  }

  #escaperooms {
    background-image: url("../assets/images/bg-escaperooms.svg");
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;

    // margin-top: -80px;
    .block-escaperooms {
      padding: 0px 70px;

      .img-escaperooms {
        width: 100%;
        max-width: 821px;
      }

      .block-media {
        display: flex;
        flex-flow: row;
        margin-top: 30px;
        justify-content: center;

        .block-video {
          width: 100%;
          // height: 100%;
          max-height: 469px;
          max-width: 820px;
          border-radius: 15px;
        }

        .block-image {
          margin-left: 90px;
          width: 100%;
          height: 100%;
          max-width: 462px;
          max-height: 467px;
          // border: 8px solid #FFFFFF;

          .box-img-star,
          .text-clock,
          .text-culture,
          .text-awreness {
            padding-top: 25px;
            margin-bottom: 0;
          }

          .text-clock {
            font-size: 55px;
            font-weight: 800;
            line-height: 64.45px;
          }

          .text-culture {
            font-size: 36px;
            font-weight: 500;
            line-height: 42.19px;
          }

          .text-awreness {
            font-size: 28px;
            font-weight: 400;
            padding-bottom: 25px;
            line-height: 32.81px;
          }
        }
      }

      .block-description {
        padding: 30px 75px;

        .block-bg {
          background: rgba(22, 22, 22, 0.8);

          .container-description {
            padding: 30px;

            .description {
              padding: 30px 145px;
              padding-bottom: 0;
              font-weight: 400;
              font-size: 24px;
              line-height: 28px;
              text-align: center;
            }
          }
        }
      }

      .block-booking {
        padding: 30px 0;

        .container-booking-room {
          margin: 30px;
          margin-bottom: 0;
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          align-items: center;

          .card-booking {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 282px;
            max-height: 400px;
            margin: 30px 45px;
            position: relative;
            .image-booking {
              width: 100%;
              max-width: 282px;
              border-radius: 5px;
            }
          }
        }

        .container-booking-room-mobile {
          display: none;
        }
      }
    }
  }

  #promotion {
    color: #ffffff;

    .block-promotion {
      padding: 60px 70px;
    }
  }

  #review {
    color: #ffffff;

    .block-review-img {
      background-image: url("../assets/images/bg-review.jpg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-review {
        padding: 40px 70px;
      }
    }
  }

  #franchise {
    color: #ffffff;

    .block-franchise {
      padding: 60px 70px;

      .block-img-franchise {
        padding-top: 60px;
      }
    }
  }

  #contact {
    color: #ffffff;

    .block-contact {
      padding: 60px 70px;

      .container-contact {
        padding-top: 60px;

        .address {
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-location {
            width: 100%;
            max-width: 38px;
            max-height: 48px;
          }

          .text-address {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 0;
            padding-left: 40px;
          }
        }

        .map {
          padding: 30px 0;
        }

        .desc {
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;

          p {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  #news {
    color: #ffffff;
    background-image: url("../assets/images/bg-news.png");
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: cover;

    .block-news {
      padding: 60px 70px;

      .container-news {
        margin: 30px;
        margin-bottom: 10px;
        display: flex;
        flex-flow: wrap row;
        justify-content: center;
        align-items: center;

        .card-news {
          box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
          border-radius: 5px;
          width: 100%;
          height: 100%;
          max-width: 392px;
          max-height: 454px;
          margin: 30px 50px;
          background-color: white;

          .image-news {
            width: 100%;
            // max-width: 392px;
            // max-height: 300px;
            border-radius: 5px 5px 0px 0px;
          }

          .description {
            // background-color: #FFFFFF;
            color: black;
            text-align: left;
            margin: 16px;

            .title {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &.p {
                margin-bottom: 8px !important;
              }
            }

            .text-desc {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              display: -webkit-box;
              max-width: 400px;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .block-date {
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #6d6d6d;

              .readmore {
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .container-news-mobile {
        display: none;
      }
    }
  }

  @media (max-width: 1730px) {
    .container-title {
      .border-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        width: max-content;
      }
    }

    #escaperooms {
      background-image: url("../assets/images/bg-escaperooms.svg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      // margin-top: -80px;
      .block-escaperooms {
        padding: 0px 70px;

        .block-media {
          display: flex;
          flex-flow: row;
          margin-top: 30px;
          justify-content: center;

          .block-video {
            width: 100%;
            // height: 100%;
            max-height: 469px;
            max-width: 820px;
            border-radius: 15px;
          }

          .block-image {
            margin-left: 90px;
            width: 100%;
            height: 100%;
            max-width: 462px;
            max-height: 467px;
            // border: 8px solid #FFFFFF;

            .box-img-star,
            .text-clock,
            .text-culture,
            .text-awreness {
              padding-top: 25px;
              margin-bottom: 0;
            }

            .text-clock {
              font-size: 55px;
              font-weight: 800;
              line-height: 64.45px;
            }

            .text-culture {
              font-size: 36px;
              font-weight: 500;
              line-height: 42.19px;
            }

            .text-awreness {
              font-size: 28px;
              font-weight: 400;
              padding-bottom: 25px;
              line-height: 32.81px;
            }
          }
        }

        .block-description {
          padding: 30px 55px;

          .block-bg {
            background: rgba(22, 22, 22, 0.8);

            .container-description {
              padding: 30px;

              .description {
                padding: 30px 145px;
                padding-bottom: 0;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
              }
            }
          }
        }

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            margin: 30px;
            margin-bottom: 0;
            display: flex;
            flex-flow: wrap row;
            justify-content: center;
            align-items: center;

            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 400px;
              max-height: 400px;
              margin: 30px 35px;
              position: relative;

              // &:first-child {
              //   margin-left: 0;
              // }

              // &:last-child {
              //   margin-right: 0;
              // }

              .image-booking {
                width: 100%;
                max-width: 400px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    #promotion {
      color: #ffffff;

      .block-promotion {
        padding: 60px 70px;
      }
    }

    #review {
      color: #ffffff;

      .block-review-img {
        background-image: url("../assets/images/bg-review.jpg");
        background-origin: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        .block-review {
          padding: 40px 70px;
        }
      }
    }

    #franchise {
      color: #ffffff;

      .block-franchise {
        padding: 60px 70px;

        .block-img-franchise {
          padding-top: 60px;
        }
      }
    }

    #contact {
      color: #ffffff;

      .block-contact {
        padding: 60px 70px;

        .container-contact {
          padding-top: 60px;

          .address {
            display: flex;
            justify-content: center;
            align-items: center;

            .text-address {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 0;
              padding-left: 40px;
            }
          }

          .map {
            padding: 30px 0;
          }

          .desc {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            p {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    #news {
      color: #ffffff;
      background-image: url("../assets/images/bg-news.png");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-news {
        padding: 60px 70px;

        .container-news {
          margin: 30px;
          margin-bottom: 10px;
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          align-items: center;

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 392px;
            max-height: 454px;
            margin: 30px 30px;
            background-color: white;

            .image-news {
              width: 100%;
              // max-width: 392px;
              // max-height: 300px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1610px) {
    .container-title {
      .border-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        width: max-content;
      }
    }

    .container-button {
      .button-default {
        width: 220px;
        height: 60px;
        background: #a70000;
        border-radius: 15px;
        color: #ffffff;
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.social {
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 60px;
        }

        &.position {
          position: absolute;
          z-index: 1;
          bottom: 20px;
        }
      }
    }

    #escaperooms {
      background-image: url("../assets/images/bg-escaperooms.svg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      // margin-top: -80px;
      .block-escaperooms {
        padding: 0px 70px;

        .block-media {
          display: flex;
          flex-flow: row;
          margin-top: 30px;
          justify-content: center;

          .block-video {
            width: 100%;
            // height: 100%;
            max-height: 469px;
            max-width: 820px;
            border-radius: 15px;
          }

          .block-image {
            margin-left: 70px;
            width: 100%;
            height: 100%;
            max-width: 462px;
            max-height: 467px;
            // border: 8px solid #FFFFFF;

            .box-img-star,
            .text-clock,
            .text-culture,
            .text-awreness {
              padding-top: 25px;
              margin-bottom: 0;
            }

            .text-clock {
              font-size: 55px;
              font-weight: 800;
              line-height: 64.45px;
            }

            .text-culture {
              font-size: 36px;
              font-weight: 500;
              line-height: 42.19px;
            }

            .text-awreness {
              font-size: 28px;
              font-weight: 400;
              padding-bottom: 25px;
              line-height: 32.81px;
            }
          }
        }

        .block-description {
          padding: 30px;

          .block-bg {
            background: rgba(22, 22, 22, 0.8);

            .container-description {
              padding: 30px;

              .description {
                padding: 30px 120px;
                padding-bottom: 0;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
              }
            }
          }
        }

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            margin: 30px;
            margin-bottom: 0;
            display: flex;
            flex-flow: wrap row;
            justify-content: center;
            align-items: center;

            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 400px;
              max-height: 400px;
              margin: 30px 25px;
              position: relative;

              // &:first-child {
              //   margin-left: 0;
              // }

              // &:last-child {
              //   margin-right: 0;
              // }

              .image-booking {
                width: 100%;
                max-width: 400px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    #promotion {
      color: #ffffff;

      .block-promotion {
        padding: 60px 70px;
      }
    }

    #review {
      color: #ffffff;

      .block-review-img {
        background-image: url("../assets/images/bg-review.jpg");
        background-origin: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        .block-review {
          padding: 40px 70px;
        }
      }
    }

    #franchise {
      color: #ffffff;

      .block-franchise {
        padding: 60px 70px;

        .block-img-franchise {
          padding-top: 60px;
        }
      }
    }

    #contact {
      color: #ffffff;

      .block-contact {
        padding: 60px 70px;

        .container-contact {
          padding-top: 60px;

          .address {
            display: flex;
            justify-content: center;
            align-items: center;

            .text-address {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 0;
              padding-left: 40px;
            }
          }

          .map {
            padding: 30px 0;
          }

          .desc {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            p {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    #news {
      color: #ffffff;
      background-image: url("../assets/images/bg-news.png");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-news {
        padding: 60px 70px;

        .container-news {
          margin: 30px;
          margin-bottom: 10px;
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          align-items: center;

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 392px;
            max-height: 454px;
            margin: 30px 20px;
            background-color: white;

            .image-news {
              width: 100%;
              // max-width: 392px;
              // max-height: 300px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .container-title {
      .border-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
      }
    }

    .container-button {
      .button-default {
        width: 220px;
        height: 60px;
        background: #a70000;
        border-radius: 15px;
        color: #ffffff;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.social {
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 40px;
        }

        &.position {
          position: absolute;
          z-index: 1;
          bottom: 20px;
        }
      }
    }

    #escaperooms {
      background-image: url("../assets/images/bg-escaperooms.svg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      // margin-top: -80px;
      .block-escaperooms {
        padding: 0px 70px;

        .block-media {
          display: flex;
          flex-flow: row;
          margin-top: 30px;
          justify-content: center;

          .block-video {
            width: 100%;
            // height: 100%;
            max-height: 469px;
            max-width: 820px;
            border-radius: 15px;
          }

          .block-image {
            margin-left: 50px;
            width: 100%;
            height: 100%;
            max-width: 462px;
            max-height: 467px;
            // border: 8px solid #FFFFFF;

            .box-img-star,
            .text-clock,
            .text-culture,
            .text-awreness {
              padding-top: 25px;
              margin-bottom: 0;
            }

            .text-clock {
              font-size: 55px;
              font-weight: 800;
              line-height: 64.45px;
            }

            .text-culture {
              font-size: 36px;
              font-weight: 500;
              line-height: 42.19px;
            }

            .text-awreness {
              font-size: 28px;
              font-weight: 400;
              padding-bottom: 25px;
              line-height: 32.81px;
            }
          }
        }

        .block-description {
          padding: 30px 0;

          .block-bg {
            background: rgba(22, 22, 22, 0.8);

            .container-description {
              padding: 30px 0;

              .description {
                padding: 30px 100px;
                padding-bottom: 0;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
              }
            }
          }
        }

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            margin: 30px;
            margin-bottom: 0;
            display: flex;
            flex-flow: wrap row;
            justify-content: center;
            align-items: center;

            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 280px;
              max-height: 400px;
              margin: 30px 25px;
              position: relative;

              // &:first-child {
              //   margin-left: 0;
              // }

              // &:last-child {
              //   margin-right: 0;
              // }

              .image-booking {
                width: 100%;
                max-width: 400px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    #promotion {
      color: #ffffff;

      .block-promotion {
        padding: 40px 70px;
      }
    }

    #review {
      color: #ffffff;

      .block-review-img {
        background-image: url("../assets/images/bg-review.jpg");
        background-origin: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        .block-review {
          padding: 40px 70px;
        }
      }
    }

    #franchise {
      color: #ffffff;

      .block-franchise {
        padding: 40px 70px;

        .block-img-franchise {
          padding-top: 60px;
        }
      }
    }

    #contact {
      color: #ffffff;

      .block-contact {
        padding: 40px 70px;

        .container-contact {
          padding-top: 40px;

          .address {
            display: flex;
            justify-content: center;
            align-items: center;

            .text-address {
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 0;
              padding-left: 40px;
            }
          }

          .map {
            padding: 30px 0;
          }

          .desc {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            p {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    #news {
      color: #ffffff;
      background-image: url("../assets/images/bg-news.png");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-news {
        padding: 60px 70px;

        .container-news {
          margin: 30px;
          margin-bottom: 10px;
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          align-items: center;

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 392px;
            max-height: 454px;
            margin: 30px 20px;
            background-color: white;

            .image-news {
              width: 100%;
              // max-width: 392px;
              // max-height: 300px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .container-title {
      .border-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
      }
    }

    .container-button {
      .button-default {
        width: 220px;
        height: 60px;
        background: #a70000;
        border-radius: 15px;
        color: #ffffff;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.social {
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 40px;
        }

        &.position {
          position: absolute;
          z-index: 1;
          bottom: 20px;
        }
      }
    }

    #escaperooms {
      background-image: url("../assets/images/bg-escaperooms.svg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      // margin-top: -80px;
      .block-escaperooms {
        padding: 0px 50px;

        .block-media {
          display: flex;
          flex-flow: row;
          margin-top: 30px;
          justify-content: center;

          .block-video {
            width: 100%;
            // height: 100%;
            max-height: 469px;
            max-width: 820px;
            border-radius: 15px;
          }

          .block-image {
            margin-left: 40px;
            width: 100%;
            height: 100%;
            max-width: 462px;
            max-height: 467px;
            // border: 8px solid #FFFFFF;

            .box-img-star,
            .text-clock,
            .text-culture,
            .text-awreness {
              padding-top: 25px;
              margin-bottom: 0;
            }

            .text-clock {
              font-size: 55px;
              font-weight: 800;
              line-height: 64.45px;
            }

            .text-culture {
              font-size: 36px;
              font-weight: 500;
              line-height: 42.19px;
            }

            .text-awreness {
              font-size: 28px;
              font-weight: 400;
              padding-bottom: 25px;
              line-height: 32.81px;
            }
          }
        }

        .block-description {
          padding: 30px 0;

          .block-bg {
            background: rgba(22, 22, 22, 0.8);

            .container-description {
              padding: 30px 0;

              .description {
                padding: 30px 80px;
                padding-bottom: 0;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
              }
            }
          }
        }

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            margin: 30px;
            margin-bottom: 0;
            display: flex;
            flex-flow: wrap row;
            justify-content: center;
            align-items: center;

            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 400px;
              max-height: 400px;
              margin: 30px 25px;
              position: relative;

              // &:first-child {
              //   margin-left: 0;
              // }

              // &:last-child {
              //   margin-right: 0;
              // }

              .image-booking {
                width: 100%;
                max-width: 400px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    #promotion {
      color: #ffffff;

      .block-promotion {
        padding: 40px 50px;
      }
    }

    #review {
      color: #ffffff;

      .block-review-img {
        background-image: url("../assets/images/bg-review.jpg");
        background-origin: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        .block-review {
          padding: 40px 50px;
        }
      }
    }

    #franchise {
      color: #ffffff;

      .block-franchise {
        padding: 40px 50px;

        .block-img-franchise {
          padding-top: 60px;
        }
      }
    }

    #contact {
      color: #ffffff;

      .block-contact {
        padding: 40px 50px;

        .container-contact {
          padding-top: 40px;

          .address {
            display: flex;
            justify-content: center;
            align-items: center;

            .text-address {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 0;
              padding-left: 40px;
            }
          }

          .map {
            padding: 30px 0;
          }

          .desc {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;

            p {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    #news {
      color: #ffffff;
      background-image: url("../assets/images/bg-news.png");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-news {
        padding: 60px 50px;

        .container-news {
          margin: 30px;
          margin-bottom: 10px;
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          align-items: center;

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 392px;
            max-height: 454px;
            margin: 30px 20px;
            background-color: white;

            .image-news {
              width: 100%;
              // max-width: 392px;
              // max-height: 300px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .container-title {
      .border-title {
        padding: 10px 12px;
        font-weight: 500;
        font-size: 24px;
        line-height: 28.13px;
        width: 100%;
      }
    }

    .container-button {
      flex-direction: column;
      align-items: center;

      .button-default {
        width: 180px;
        height: 40px;
        background: #a70000;
        border-radius: 15px;
        color: #ffffff;
        font-weight: 800;
        font-size: 16px;
        line-height: 18.75px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.social {
          width: 220px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0;

          .icon-social {
            &.whatapp {
              max-width: 19px;
              max-height: 19px;
            }

            width: 100%;
            max-width: 24px;
            max-height: 24px;
          }
        }

        &.position {
          position: absolute;
          z-index: 1;
          bottom: 20px;
        }
      }
    }

    .react-player {
      &.video {
        max-height: 218px;
        max-width: 380px;
      }
    }

    .d-block {
      &.size-promotion {
        width: 100%;
        height: 100%;
        // max-width: 380px;
        max-height: 100%;
      }

      &.size-review {
        width: 100%;
        height: auto;
        max-height: max-content;
        max-width: max-content;
        margin: 0 auto;
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
        border-radius: 10px;
      }
    }

    .container-slide {
      padding: 30px 0;

      &.booking {

        // .carousel-item {
        //   box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
        //   width: 300px;
        // }
        .carousel-indicators {
          display: none;
        }
      }

      &.promotion {
        .carousel-indicators {
          display: none;
        }

      }

      &.review {
        // padding: 60px 150px 30px 150px;
        display: flex;
        justify-content: center;

        .carousel {
          width: 100%;
        }

        .carousel-control-prev,
        .carousel-control-next {
          display: none;
        }

        .carousel-indicators {
          margin-bottom: -2rem;

          button {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            // border: 2px solid white;
            // background-color:#747474;
            // background: transparent;
          }
        }
      }

      .card {
        border: none;

        .card-body {
          padding: 0 !important;
        }
      }

      // .h-100 {
      //   &.booking {
      //     // box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25)
      //   }
      // }
    }

    #escaperooms {
      background-image: url("../assets/images/bg-escaperooms.svg");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      // margin-top: -80px;
      .block-escaperooms {
        padding: 0px 25px;

        .img-escaperooms {
          width: 100%;
          max-width: 376px;
          max-height: 152px;
        }

        .block-media {
          display: flex;
          flex-flow: column;
          margin-top: 15px;
          justify-content: center;
          align-items: center;

          .block-video {
            width: 100%;
            height: 218px;
            max-height: 218px;
            max-width: 380px;
            border-radius: 15px;
          }

          .block-image {
            margin-left: 0px;
            margin-top: 20px;
            width: 100%;
            height: 100%;
            max-width: 380px;
            max-height: 384px;
            // border: 8px solid #FFFFFF;

            .box-img-star,
            .text-clock,
            .text-culture,
            .text-awreness {
              padding-top: 25px;
              margin-bottom: 0;
            }

            .text-clock {
              font-size: 55px;
              font-weight: 800;
              line-height: 64.45px;
            }

            .text-culture {
              font-size: 36px;
              font-weight: 500;
              line-height: 42.19px;
            }

            .text-awreness {
              font-size: 28px;
              font-weight: 400;
              padding-bottom: 25px;
              line-height: 32.81px;
            }
          }
        }

        .block-description {
          padding: 30px 0;

          .block-bg {
            background: none;

            .container-description {
              padding: 30px 0;
              padding-bottom: 0;

              .description {
                padding: 30px 0px;
                padding-bottom: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 18.75px;
                text-align: center;
              }
            }
          }
        }

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            display: none;
          }

          .container-booking-room-mobile {
            display: block;

            .slick-slide {
              width: 330px
            }

            .slick-slider {
              height: 300px;
              .slick-list {
                height: 300px;
              }
            }


            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 300px;
              max-height: 300px;
              margin: 0;
              position: relative;

              .image-booking {
                width: 100%;
                max-width: 300px;
                max-height: 300px;
                border-radius: 5px;
              }
            }
          }

        }
      }
    }

    #promotion {
      color: #ffffff;

      .block-promotion {
        padding: 30px 25px;
      }
    }

    #review {
      color: #ffffff;

      .block-review-img {
        background-image: url("../assets/images/bg-review.jpg");
        background-origin: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        .block-review {
          padding: 40px 25px;
        }
      }
    }

    #franchise {
      color: #ffffff;

      .block-franchise {
        padding: 30px 25px;

        .block-img-franchise {
          padding-top: 35px;
        }
      }
    }

    #contact {
      color: #ffffff;

      .block-contact {
        padding: 40px 25px;

        .container-contact {
          padding-top: 15px;

          .address {
            display: flex;
            justify-content: center;
            align-items: center;

            .icon-location {
              width: 100%;
              max-width: 16px;
              max-height: 20px;
            }

            .text-address {
              font-weight: 500;
              font-size: 16px;
              line-height: 18.75px;
              margin-bottom: 0;
              padding-left: 10px;
            }
          }

          .map {
            padding: 15px 0;
          }

          .desc {
            font-weight: 500;
            font-size: 14px;
            line-height: 16.41px;

            p {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    #news {
      color: #ffffff;
      background-image: url("../assets/images/bg-news.png");
      background-origin: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      .block-news {
        padding: 30px 25px;

        .container-news {
          display: none;
        }

        .container-news-mobile {
          margin: 30px;
          margin-bottom: 10px;
          display: block;

          .slick-slider {
            height: 440px;

            .slick-list {
              height: 440px;
            }
          }

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 300px;
            max-height: 440px;
            margin: 30px 15px;
            background-color: white;

            .image-news {
              width: 100%;
              max-width: 300px;
              max-height: 440px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 360px) {

    #escaperooms {

      .block-escaperooms {

        .block-booking {
          padding: 30px 0;

          .container-booking-room {
            display: none;
          }

          .container-booking-room-mobile {
            display: block;

            .slick-slide {
              width: 280px
            }

            .slick-slider {
              height: 250px;
              .slick-list {
                height: 250px;
              }
            }


            .card-booking {
              box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
              border-radius: 5px;
              width: 100%;
              height: 100%;
              max-width: 250px;
              max-height: 250px;
              margin: 0;
              position: relative;

              .image-booking {
                width: 100%;
                max-width: 250px;
                max-height: 250px;
                border-radius: 5px;
              }
            }
          }

        }
      }
    }

    #news {

      .block-news {
        padding: 30px 25px;

        .container-news {
          display: none;
        }

        .container-news-mobile {
          margin: 30px;
          margin-bottom: 10px;
          display: block;

          .slick-slider {
            height: 400px;

            .slick-list {
              height: 400px;
            }
          }

          .card-news {
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            max-width: 250px;
            max-height: 440px;
            margin: 30px 15px;
            background-color: white;

            .image-news {
              width: 100%;
              max-width: 300px;
              max-height: 440px;
              border-radius: 5px 5px 0px 0px;
            }

            .description {
              // background-color: #FFFFFF;
              color: black;
              text-align: left;
              margin: 16px;

              .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.p {
                  margin-bottom: 8px !important;
                }
              }

              .text-desc {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .block-date {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: #6d6d6d;
              }
            }
          }
        }
      }
    }
  }
}
